import * as React from "react";
import "./styles.scss";
import StyledLink from "../StyledLink/StyledLink";
import { useEffect, useState } from "react";
import Qr from "../../_utils/Qr";

export default function ClaimRewardBtn({ text }) {
    const [showTbn, setShowTbn] = useState(false);

    useEffect(() => {
        if (!!Qr.hasStoredParams()) {
            setShowTbn(true);
        }
    }, []);

    return !!showTbn ? (
        <StyledLink rewardsButton to="/registration/">
            {text ? text : "Unlock my gift"}
        </StyledLink>
    ) : <div className="no-button"></div>;
}
