import React from "react";
import Layout from "../_components/Layout/Layout";
import Banner from "../_components/Banner/Banner";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";
import { bannerList } from "../_components/constants";

const Home = () => {
    return (
        <Layout title="Welcome" hideSubNav={false}>
            <div className="title-wrapper">
                <MainTitle className="home">Welcome to GLIDE</MainTitle>

                <PageSummary>
                    As a thank you for scanning the QR code you will receive a gift from us after registering your details.
                </PageSummary>
            </div>

            <ClaimRewardBtn />

            {bannerList?.map((item, index) => (
                <Banner
                    key={index}
                    image={item.image}
                    text={item.text}
                    location={item.location}
                    newWindow={item?.newWindow}
                />
            ))}
        </Layout>
    );
};
export default Home;
