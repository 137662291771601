import * as React from "react";
import { Link, navigate } from "gatsby";
import "./styles.scss";

const StyledLink = ({ children, to, asButton, rewardsButton, className }) => {
    return (
        <>
            {asButton ? (
                <Link className="link-component btn btn-primary btn-block" to={to}>
                    {children}
                </Link>
            ) : rewardsButton ? (
                <div className={`button-container ${className ? className : ''}`}>
                    <button
                        type="button"
                        className="btn btn-rewards"
                        onClick={() => navigate(to)}
                    >
                        <span>
                            {children}
                        </span>
                    </button>
                </div>
            ) : (
                <Link className="link-component" to={to}>
                    {children}
                </Link>
            )}
        </>
    );
};
export default StyledLink;
